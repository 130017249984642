import Swal from "sweetalert2";
import { Tab } from "bootstrap";
import Rapport from "./Rapport";

export default class Equipments {
	constructor() {
		new Rapport(false);
		const unarchiveButtons = document.querySelectorAll(".js-unarchive-button");
		unarchiveButtons.forEach(btn => {
			btn.addEventListener("click", this.onClickUnarchive);
		});

		const deleteButtons = document.querySelectorAll(".js-delete-button");
		deleteButtons.forEach(btn => {
			btn.addEventListener("click", this.onClickDelete);
		});

		const exportButtons = document.querySelectorAll(".js-export-button");
		exportButtons.forEach(btn => {
			btn.addEventListener("click", this.onClickExport);
		});

		const clearButton = document.querySelector(".js-clear");
		if (clearButton) {
			if (document.querySelector("#keyword").value == "") {
				document.querySelector(".js-clear").classList.remove("d-flex");
			}
			clearButton.addEventListener("click", this.onClickClear);
		}

		this.initTabs();
	}

	initTabs() {
		const tabs = document.querySelector("#tabs");
		const activeTab = localStorage.getItem("equipments-activeTab");

		tabs.addEventListener("shown.bs.tab", e => {
			localStorage.setItem("equipments-activeTab", e.target.dataset.bsTarget);
		});

		if (activeTab) {
			const tab = new Tab(document.querySelector([`[data-bs-target="${activeTab}"]`]));
			tab.show();
		}
	}

	onClickClear() {
		history.back();
	}

	onClickExport(e) {
		e.preventDefault();
		const id = e.currentTarget.dataset.id;
		const install_date = e.currentTarget.dataset.install;
		const withdraw_date = e.currentTarget.dataset.withdraw;

		const currentDate = new Date();
		const year = currentDate.getFullYear();
		const month = String(currentDate.getMonth() + 1).padStart(2, "0");
		const day = String(currentDate.getDate()).padStart(2, "0");

		const formattedDate = `${year}-${month}-${day}`;

		document.querySelector("#r_started_at").value = install_date;
		document.querySelector("#r_ended_at").value = withdraw_date ? withdraw_date : formattedDate;

		document.querySelector("#reportModal").dataset.id = id;
	}

	onClickDelete(e) {
		e.preventDefault();
		const id = e.currentTarget.dataset.id;

		Swal.fire({
			title: "Êtes-vous sûr ?",
			text: "Cette équipement sera archivé !",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#2d72bb",
			cancelButtonColor: "#e3342f",
			cancelButtonText: "Annuler",
			confirmButtonText: "Confirmer",
		}).then(result => {
			if (result.value) {
				axios
					.post("/equipments/archive", {
						id,
					})
					.then(response => {
						window.location.reload();
					})
					.catch(error => {
						console.log(error);
					});
			}
		});
	}

	onClickUnarchive(e) {
		e.preventDefault();
		const id = e.currentTarget.dataset.id;

		axios
			.post("/equipments/unarchive", {
				id,
			})
			.then(response => {
				window.location.href = response.data;
			})
			.catch(error => {
				console.log(error);
			});
	}
}
