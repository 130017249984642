require('./bootstrap');
import Note from './Note';
import Equipments from './Equipments';
import Stations from './Stations';
import Calendar from './Calendar';
import Swal from 'sweetalert2';
document.addEventListener("DOMContentLoaded", () => {
	initMenu();

	if(document.querySelector('.note-page')){
		new Note();
	}
	if(document.querySelector('.equipments-page')){
		new Equipments();
	}
	if(document.querySelector('.stations-page')){
		new Stations();
	}
	if(document.querySelector('.calendar-page')){
		new Calendar();
	}


	document.body.addEventListener("click", function (event) {

		if (event.target.classList.contains("js-btn-destroy-datas")) {
		    event.preventDefault();

		    let id = event.target.id;
		    let path = null;
		    let idToDelete = null;

		    if(id == 'is_child') {
		        path = event.target.parentElement.href;
		        idToDelete = event.target.parentElement.dataset.id;
		    } else {
		        path = event.target.href;
		        idToDelete = event.target.dataset.id;
		    }

		    Swal.fire({
		        title: 'Êtes-vous sûr ?',
		        text: 'Cette entrée sera supprimée !',
		        icon: 'warning',
		        showCancelButton: true,
		        confirmButtonColor: '#2d72bb',
		        cancelButtonColor: '#e3342f',
		        cancelButtonText: 'Annuler',
		        confirmButtonText: 'Confirmer'
		    }).then((result) => {
		        if (result.value) {

		            axios.post(path, {
		                id: idToDelete,
		            })
		            .then(function (response) {
		                window.location.href = response.data;
		            })
		            .catch(function (error) {
		                console.log(error);
		            });
		        }
		    });
		}
	});

	function initMenu() {
		const menu =document.querySelector('#mobile-menu');
		if(menu){
			menu.addEventListener('change', e => {
				const value = e.target.value
				window.location.href = value
			})
		}
	}
});
