import Swal from 'sweetalert2';
import { Modal } from 'bootstrap';
export default class Note {
	constructor() {
		this.isEdit = false;
		this.noteId = '';
		this.onClickDeleteNote = this.onClickDeleteNote.bind(this);
		this.onSubmitNoteForm = this.onSubmitNoteForm.bind(this);
		const deleteNoteBtns = document.querySelectorAll('.js-delete-note');
		deleteNoteBtns.forEach( btn => {
			btn.addEventListener('click', (event) => this.onClickDeleteNote(event, btn));
		})

		this.noteModal = new Modal(document.getElementById('noteModal'), {
			backdrop: 'static'
		});

		const createNoteBtn = document.querySelector('.js-open-note-modal');
		if(createNoteBtn){
			this.noteId = '';
			createNoteBtn.addEventListener('click', this.onClickCreateNote.bind(this))
		}

		const editNoteBtns = document.querySelectorAll('.js-edit-note');
		editNoteBtns.forEach( btn => {
			btn.addEventListener('click', this.onClickEditNote.bind(this));
		});

		this.noteForm = document.querySelector('.modal-note-form');
		if(this.noteForm){
			this.notesContainer = document.querySelector('.notes-container');

			this.noteForm.addEventListener('submit', this.onSubmitNoteForm)
		}
	}

	onClickCreateNote(event) {
		event.preventDefault();
		if(this.noteModal){
			this.isEdit = false;
			this.noteModal.show();
			this.noteForm.reset();
			this.focusModal();
		}
	}

	onClickDeleteNote(event, btn) {
		event.preventDefault();
	    Swal.fire({
	        title: 'Êtes-vous sûr ?',
	        text: 'Cette entrée sera supprimée',
	        icon: 'warning',
	        showCancelButton: true,
	        confirmButtonColor: '#2d72bb',
	        cancelButtonColor: '#e3342f',
	        cancelButtonText: 'Annuler',
	        confirmButtonText: 'Confirmer'
	    }).then((result) => {
	        if (result.value) {
	        	btn.parentNode.parentNode.parentNode.parentNode.remove()
	        }
	    });
	}

	onClickEditNote(event) {
		this.noteId = event.currentTarget.dataset.id;
		this.lastEditedNote = event.currentTarget.parentNode.parentNode.parentNode;

		if(this.noteModal){
			this.isEdit = true;
			this.noteForm.elements.note.value = this.lastEditedNote.querySelector('.card-text').innerHTML
			this.noteModal.show();

			setTimeout(()=> {
				this.focusModal();
			},100)
		}
	}

	onSubmitNoteForm(event) {
		event.preventDefault();
		const date = new Date();
		const today = date.getFullYear() + '-' + ('0' + (date.getMonth()+1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
		const value = this.noteForm.elements.note.value;
		const index = this.notesContainer.childElementCount;

		if(! this.isEdit){// new note
			this.notesContainer.insertAdjacentHTML('beforeend',`
				<div class="col-md-12 mb-3 note">
				    <div class="card">
				        <div class="card-body text-dark">
				        	<input type="hidden" name="notes[${index+1}][value]" value="${value}">
				        	<input type="hidden" name="notes[${index+1}][created_at]" value="${today}">
				            <p class="card-text">${value}</p>
				        </div>
				        <div class="card-footer d-flex justify-content-between align-items-center">
				            <small>Date de création: ${today}</small>
				            <small>
				                <button type="button" class="btn btn-outline-primary js-edit-note" title="Modifier" href="#">
				                    <i class="fas fa-pen"></i>
				                </button>
				                <button type="button" class="btn btn-outline-primary js-delete-note" data-id="" title="Supprimer" href="#">
				                    <i class="fas fa-trash" id="is_child"></i>
				                </button>
				            </small>
				        </div>
				    </div>
				</div>
			`);

			this.notesContainer.lastElementChild.querySelector('.js-edit-note').addEventListener('click', this.onClickEditNote.bind(this))

			const deleteBtn = this.notesContainer.lastElementChild.querySelector('.js-delete-note');
			deleteBtn.addEventListener('click', (event) => this.onClickDeleteNote(event, deleteBtn))
		}else{
			console.log('note edited');
			this.lastEditedNote.querySelector('.card-text').innerHTML = value
			this.lastEditedNote.querySelector('input').value = value
		}

		this.noteModal.hide();
	}

	focusModal() {
		const el = this.noteForm.elements.note;
		el.focus();
		el.setSelectionRange(el.value.length, el.value.length);
	}
}
