import Swal from "sweetalert2";
import $ from "jquery";
import moment from "moment";
import "fullcalendar";
import { Modal } from "bootstrap";
import Rapport from "./Rapport";
import TodoList from "./Calendar/TodoList";
import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js";
export default class Calendar {
	constructor() {
		new Rapport();
		this.todoList = new TodoList();
		$.ajaxSetup({
			headers: {
				"X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
			},
		});

		this.myModal = {
			instance: new Modal(document.getElementById("EventModal"), {
				backdrop: "static",
			}),
			title: document.querySelector(".modal-title"),
			btnContainer: document.querySelector(".btn-container"),
			save: {
				container: document.querySelector(".save-container"),
				btn: document.querySelector(".save-button"),
				linked: document.querySelector(".save-linked"),
			},
			delete: {
				container: document.querySelector(".delete-container"),
				btn: document.querySelector(".delete-button"),
				linked: document.querySelector(".delete-linked"),
				future: document.querySelector(".delete-future"),
			},
			cycleContainer: document.querySelector(".cycle-date"),
			form: document.getElementById("modal-form"),
			errors: document.querySelector(".modal-errors small"),
		};

		this.inputs = {
			title: document.getElementById("title"),
			note: document.getElementById("note"),
			equipment_id: document.getElementById("equipment_id"),
			users: document.getElementById("users"),
			frequencies: document.querySelectorAll('input[name="frequency"]'),
			started_at: document.getElementById("started_at"),
			completed: document.getElementById("completed"),
			completed_date: document.getElementById("completed_date"),
			ended_at_group: document.getElementById("ended_at_group"),
			ended_at: document.getElementById("ended_at"),
			end_cycle: document.getElementById("end_cycle"),
		};
		this.myModal.save.btn.addEventListener("click", () => this.saveEvent("single"));
		this.myModal.save.linked.addEventListener("click", () => this.saveEvent("linked"));
		this.myModal.delete.btn.addEventListener("click", () => this.deleteEvent("single"));
		this.myModal.delete.linked.addEventListener("click", () => this.deleteEvent("linked"));
		this.myModal.delete.future.addEventListener("click", () => this.deleteEvent("future"));
		[...this.inputs.frequencies].forEach(input => {
			input.addEventListener("change", this.onChangeFrequency.bind(this));
		});

		this.inputs.completed.addEventListener("click", e => {
			this.inputs.completed_date.classList[e.target.checked ? "remove" : "add"]("d-none");
			if (!this.inputs.completed_date.value) {
				var picker = flatpickr(this.inputs.completed_date, {
					enableTime: true,
					enableSeconds: true,
					minuteIncrement: 1,
					dateFormat: "Y-m-d H:i:S",
					locale: French,
				});

				picker.setDate(new Date());
			}
		});

		this.initCalendar();

		Date.prototype.toDateInputValue = function () {
			const local = new Date(this);
			local.setMinutes(this.getMinutes());
			return local.toJSON().slice(0, 10);
		};

		const urlParams = new URLSearchParams(window.location.search);
		const equipment_id = urlParams.get("id");
		if (equipment_id) {
			this.onSelectCalendarDay(
				moment().format("YYYY-MM-DD"),
				moment().add(1, "days").format("YYYY-MM-DD"),
				null,
				equipment_id
			);
		}
	}

	deleteEvent(type) {
		const id = this.myModal.delete.container.dataset.id;
		const data = {
			id,
			type,
		};

		Swal.fire({
			title: "Êtes-vous sûr ?",
			text: "Cette tâche sera supprimée !",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#e3342f",
			cancelButtonColor: "#2d72bb",
			cancelButtonText: "Annuler",
			confirmButtonText: "Supprimer",
		}).then(result => {
			if (result.isDismissed) {
				return;
			}

			$.ajax({
				type: "POST",
				url: "/tasks/delete",
				data,
				success: response => {
					$("#calendar").fullCalendar("refetchEvents");
					this.displayMessage("Supprimé avec succès");
					this.myModal.instance.hide();
					this.myModal.form.reset();
				},
			});
		});
	}

	initCalendar() {
		this.calendar = $("#calendar").fullCalendar({
			editable: true,
			events: "/tasks",
			timezone: "local",
			displayEventTime: false,
			locale: "fr",
			nextDayThreshold: "00:00:00",
			selectable: true,
			eventColor: "#2d72bb",
			selectHelper: true,
			longPressDelay: 1,
			select: this.onSelectCalendarDay.bind(this),
			eventRender: function (event, $el) {
				let todos = event.todos;

				if (todos && todos.length) {
					let completed = todos.filter(item => item.checked == "true");
					$el.find(".fc-title").text(completed.length + "/" + event.todos.length + " " + event.title);
				}

				if (event.completed) {
					$el.css("opacity", 0.5);
				}
			},
			eventDrop: (event, delta) => {
				this.prepareEvent(event);

				this.saveEvent();
			},
			eventClick: event => {
				this.prepareEvent(event);

				this.myModal.instance.show();
			},
		});
	}

	displayMessage(message) {
		$(".response").html("" + message + "");
		setTimeout(() => {
			$(".response").fadeOut();
		}, 1000);
	}

	prepareEvent(event) {
		console.log(event);
		this.myModal.title.innerHTML = "Modifier une tâche";
		this.inputs.title.value = event.title;
		this.inputs.note.value = event.note;
		this.inputs.equipment_id.value = event.equipment_id;
		this.inputs.completed.checked = event.completed;
		this.inputs.users.value = event.user_id;
		this.inputs.end_cycle.value = event.end_cycle;
		if (event.frequency && document.querySelector("#" + event.frequency)) {
			document.querySelector("#" + event.frequency).checked = true;
		}
		this.inputs.started_at.value = moment(event.start).format("YYYY-MM-DD");
		this.inputs.ended_at.value = moment(event.end).format("YYYY-MM-DD");
		this.inputs.frequencies.forEach(freq => {
			freq.disabled = true;
		});

		var picker = flatpickr(this.inputs.completed_date, {
			enableTime: true,
			enableSeconds: true,
			minuteIncrement: 1,
			dateFormat: "Y-m-d H:i:S",
			locale: French,
		});

		picker.setDate(event.completed_on ? event.completed_on : new Date());

		if (event.completed) {
			this.inputs.completed_date.classList.remove("d-none");
		} else {
			this.inputs.completed_date.classList.add("d-none");
		}

		this.myModal.delete.container.dataset.id = event.id;
		this.myModal.delete.container.style.display = "";
		this.myModal.errors.innerHTML = "";
		this.myModal.btnContainer.classList.remove("flex-row-reverse");
		this.myModal.save.container.classList.add("btn-group");
		this.myModal.btnContainer.querySelector(".dropstart").style.display = "";
		if (event.frequency == "unique") {
			this.myModal.btnContainer.querySelector(".btn-danger.dropdown-toggle").style.display = "none";
			this.myModal.btnContainer.querySelector(".dropstart").style.display = "none";
			this.myModal.delete.container.classList.remove("btn-group");
			this.myModal.save.container.classList.remove("btn-group");
			this.myModal.cycleContainer.style.display = "none";
		} else {
			this.myModal.btnContainer.querySelector(".btn-danger.dropdown-toggle").style.display = "";
			this.myModal.delete.container.classList.add("btn-group");
			this.myModal.cycleContainer.style.display = "";
		}

		this.todoList.set(event);
	}

	onSelectCalendarDay(start, end, allDay, equipment_id) {
		this.myModal.title.innerHTML = "Créer une tâche";
		this.inputs.started_at.value = new Date(start).toDateInputValue();
		this.inputs.completed.checked = false;
		this.inputs.title.value = "";
		this.inputs.users.value = "";
		this.inputs.note.value = "";
		this.inputs.equipment_id.value = equipment_id ? equipment_id : "";
		document.querySelector("#unique").checked = true;

		this.inputs.completed_date.classList.add("d-none");

		const endDate = new Date(end);
		const beforeDay = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
		this.inputs.ended_at.value = moment(beforeDay).format("YYYY-MM-DD");

		delete this.myModal.save.container.dataset.id;
		delete this.myModal.delete.container.dataset.id;
		this.myModal.delete.container.style.display = "none";
		this.myModal.btnContainer.classList.add("flex-row-reverse");
		this.myModal.btnContainer.querySelector(".dropstart").style.display = "none";
		this.myModal.save.container.classList.remove("btn-group");
		this.myModal.errors.innerHTML = "";
		this.myModal.cycleContainer.style.display = "none";
		this.inputs.frequencies.forEach(freq => {
			freq.disabled = false;
		});

		this.myModal.instance.show();
		this.calendar.fullCalendar("unselect");
		this.todoList.clearTodos();
	}

	onChangeFrequency(event) {
		const value = event.target.value;

		if (value == "unique") {
			//removes error message
			this.myModal.errors.innerHTML = "";
		}

		this.myModal.cycleContainer.style.display = value == "unique" ? "none" : "";
	}

	saveEvent(type) {
		const optionSelected = $("#equipment_id option:selected");
		const start = this.inputs.started_at.value;
		const end = this.inputs.ended_at.value;
		const end_cycle = this.inputs.end_cycle.value;
		const title = this.inputs.title.value;
		const note = this.inputs.note.value;
		const equipment_id = this.inputs.equipment_id.value;
		const user = this.inputs.users.value;
		const completed = this.inputs.completed.checked;
		const completed_on = this.inputs.completed_date.value;
		const eventID = this.myModal.delete.container.dataset.id;
		const frequency = document.querySelector('input[name="frequency"]:checked').value;
		const todos = this.todoList.getPayload();

		if (this.isEmpty(optionSelected.val())) {
			this.myModal.errors.innerHTML = "Veuillez sélectionner un équipement.";
			return;
		}
		if (this.isEmpty(start) || this.isEmpty(end)) {
			this.myModal.errors.innerHTML = "Veuillez sélectionner une date de début et de fin.";
			return;
		}
		if (this.isEmpty(title)) {
			this.myModal.errors.innerHTML = "Veuillez sélectionner un titre.";
			return;
		}
		if (this.isEmpty(user)) {
			this.myModal.errors.innerHTML = "Veuillez sélectionner un utilisateur.";
			return;
		}

		if (moment(start).isAfter(end)) {
			this.myModal.errors.innerHTML = "La date de fin ne peut pas être avant la date de début.";
			return;
		}

		if (moment(end_cycle).isBefore(end) || moment(end_cycle).isBefore(start)) {
			this.myModal.errors.innerHTML =
				"La date 'Fin du cycle' ne peut pas être avant la date de début ou de fin de cette tâche.";
			return;
		}

		if (frequency != "unique" && this.isEmpty(end_cycle)) {
			this.myModal.errors.innerHTML = "Veuillez mettre une date de fin de cycle.";
			return;
		}

		let data = {
			title,
			note,
			start,
			end,
			equipment_id,
			completed,
			completed_on,
			type,
			todos,
		};

		let url = "/tasks/create";
		let method = "POST";

		if (eventID) {
			url = "/tasks/update";
			method = "PATCH";
			data.id = eventID;
		} else {
			data.frequency = frequency;
		}

		if (frequency != "unique") {
			data.end_cycle = this.inputs.end_cycle.value;
		}

		if (user) {
			data.user = user;
		}

		$.ajax({
			url,
			data,
			method,
			success: data => {
				this.displayMessage(eventID ? "Sauvegarder avec succès" : "Ajouté avec succès");
				this.myModal.instance.hide();
				this.myModal.form.reset();
				$("#calendar").fullCalendar("refetchEvents");
			},
		});
	}

	isEmpty(value) {
		if (typeof value != "string") return false;

		return value.trim().length == 0;
	}
}
