import axios from "axios";
import Tooltip from "./Rapport/Tooltip";

export default class Rapport {
	constructor(isCalendar = true) {
		this.isCalendar = isCalendar;
		this.equipements = document.querySelector("#r_equipment");
		this.equipementsContainer = document.querySelector(".chosen-equipments");
		this.onSelectEquipement = this.onSelectEquipement.bind(this);
		this.onDeleteEquipment = this.onDeleteEquipment.bind(this);
		this.sendRapportRequest = this.sendRapportRequest.bind(this);
		this.rapportModal = document.querySelector("#reportModal");
		this.rapportForm = document.querySelector("#reportModal form");
		this.generateBtn = this.rapportModal.querySelector(".save-button");
		this.errors = this.rapportModal.querySelector(".modal-rapport-errors");
		this.message = this.rapportModal.querySelector(".modal-rapport-message");
		this.isAllEquipmentSelected = false;
		if (this.equipements) {
			this.equipements.addEventListener("change", this.onSelectEquipement);
		}
		if (this.generateBtn) {
			this.generateBtn.addEventListener("click", this.sendRapportRequest);
		}

		this.rapportModal.addEventListener("hidden.bs.modal", e => {
			this.rapportModal.querySelector(".modal-body").classList.remove("d-none");
			this.generateBtn.classList.remove("d-none");

			this.formReset();
		});

		if (this.equipementsContainer) {
			this.attachEvents();
		}
	}

	formReset() {
		this.rapportModal.querySelector("#r_started_at").value = "";
		this.rapportModal.querySelector("#r_ended_at").value = new Date().toISOString().split("T")[0];
		this.equipementsContainer.innerHTML = "";
		this.errors.innerHTML = "";
		this.message.innerHTML = "";
		this.equipements.disabled = false;
	}

	attachEvents() {
		this.equipementsContainer.querySelectorAll(".chosen-equipment").forEach(el => {
			el.querySelector(".js-delete-rapport").removeEventListener("click", this.onDeleteEquipment);
			el.querySelector(".js-delete-rapport").addEventListener("click", this.onDeleteEquipment);
		});
	}

	onDeleteEquipment(e) {
		e.currentTarget.parentNode.remove();
		this.equipements.disabled = false;
		this.message.innerHTML = "";
	}

	onSelectEquipement(e) {
		const target = e.target;
		const optionsArray = Array.from(target.options);
		const foundOption = optionsArray.find(option => option.selected);
		this.isAllEquipmentSelected = target.value == "all";

		if (this.isAllEquipmentSelected) {
			this.equipementsContainer.innerHTML = "";
			this.equipements.disabled = true;
			this.message.innerHTML =
				"Ce rapport peut prendre un certain temps à générer. </br> Veuillez accéder à la page de rapport dans quelques secondes pour le télécharger.";
		} else {
			this.message.innerHTML = "";
		}

		target.value = "";
		const selectedIds = [...this.equipementsContainer.querySelectorAll(".chosen-equipment")];

		if (
			!foundOption ||
			(selectedIds.length && selectedIds.filter(el => el.dataset.id == foundOption.value).length)
		) {
			return;
		}

		this.equipementsContainer.insertAdjacentHTML(
			"beforeend",
			`
			<div class="d-flex justify-content-between align-items-center chosen-equipment py-1 px-3" data-id="${
				foundOption.value
			}">
				<small class="flex-grow-1">${foundOption.text}</small>
				<i class="me-4 rounded-circle border-1 border border-primary text-primary px-2">?</i>
				<div class="popper-tooltip bg-primary text-white bg-primary px-2 py-2 rounded"><small>Installation: ${
					foundOption.dataset.installationDate
				} ${
				foundOption.dataset.withdrawDate ? "</br>Retrait: " + foundOption.dataset.withdrawDate : ""
			}</small></div>
				<button type="button" class="btn btn-outline-primary js-delete-rapport"><i class="fas fa-trash"></i></button>
			</div>
		`
		);

		new Tooltip(
			this.equipementsContainer.lastElementChild.querySelector("i"),
			this.equipementsContainer.lastElementChild.querySelector(".popper-tooltip")
		);
		this.attachEvents();
	}

	sendRapportRequest() {
		const from = this.rapportModal.querySelector("#r_started_at").value;
		const to = this.rapportModal.querySelector("#r_ended_at").value;
		const includeArchivedEquipment = this.rapportModal.querySelector("#includeArchivedEquipment")?.checked ?? false;

		if (this.isEmpty(from) || this.isEmpty(to)) {
			this.errors.innerHTML = "Veuillez remplir les champs nécessaires.";
			return;
		}

		this.errors.innerHTML = "";

		let equipmentIds = [];

		if (this.equipementsContainer) {
			let selectedEquipments = this.equipementsContainer.querySelectorAll(".chosen-equipment");

			if (!selectedEquipments.length) {
				this.errors.innerHTML = "Veuillez choisir un équipement.";
				return;
			}

			equipmentIds = this.isAllEquipmentSelected ? [] : [...selectedEquipments].map(el => el.dataset.id);
		} else {
			equipmentIds = [this.rapportModal.dataset.id];
		}

		const data = {
			from,
			to,
			equipmentIds,
			includeArchivedEquipment
		};

		if(this.rapportModal.querySelector('input[name=r_completed]:checked')) {
			data.completed = this.rapportModal.querySelector('input[name=r_completed]:checked').value;
		}

		this.generateBtn.classList.remove("d-none");
		this.generateBtn.disabled = true;
		this.generateBtn.parentNode.querySelector("img").classList.add("d-block");
		this.generateBtn.parentNode.querySelector("img").classList.remove("d-none");
		this.generateBtn.querySelector("span").classList.add("d-none");

		axios
			.post(`/reports/${this.isCalendar ? "tasks" : "equipements"}`, data)
			.then(response => {
				if (response?.data?.path) {
					this.downloadURI(response.data.path, response.data.name);
				} else if (response?.data?.async) {
					this.rapportModal.querySelector(".modal-body").classList.add("d-none");
					this.generateBtn.classList.add("d-none");
				} else {
					this.errors.innerHTML = "Aucun entretien trouvé";
				}

				this.showGenerateReportButton();
			})
			.catch(err => {
				this.showGenerateReportButton();
			});
	}

	showGenerateReportButton() {
		this.generateBtn.disabled = false;
		this.generateBtn.parentNode.querySelector("img").classList.remove("d-block");
		this.generateBtn.parentNode.querySelector("img").classList.add("d-none");
		this.generateBtn.querySelector("span").classList.remove("d-none");
	}

	downloadURI(uri, name = "Station-epuration-rapport") {
		var link = document.createElement("a");
		link.setAttribute("download", name);
		link.setAttribute("target", "_blank");
		link.href = uri;
		document.body.appendChild(link);
		link.click();
		link.remove();
	}

	isEmpty(value) {
		if (typeof value != "string") return false;

		return value.trim().length == 0;
	}
}
