import { createPopper } from "@popperjs/core";

export default class Tooltip {
	constructor(button, tooltip) {
		this.tooltip = tooltip;
		this.popperInstance = createPopper(button, this.tooltip, {
			placement: "top",
			modifiers: [
				{
					name: "offset",
					options: {
						offset: [0, 8],
					},
				},
			],
		});
		const showEvents = ["mouseenter", "focus"];
		const hideEvents = ["mouseleave", "blur"];

		showEvents.forEach(event => {
			button.addEventListener(event, this.show.bind(this));
		});

		hideEvents.forEach(event => {
			button.addEventListener(event, this.hide.bind(this));
		});
	}
	show() {
		this.tooltip.setAttribute("data-show", "");

		this.popperInstance.update();
	}

	hide() {
		this.tooltip.removeAttribute("data-show");
	}
}
