import Swal from 'sweetalert2';
import { Tab } from 'bootstrap';

export default class Stations {
	constructor() {
		const unarchiveButtons = document.querySelectorAll('.js-unarchive-button');
		unarchiveButtons.forEach( btn => {
			btn.addEventListener('click', this.onClickUnarchive);
		});

		const deleteButtons = document.querySelectorAll('.js-delete-button');
		deleteButtons.forEach( btn => {
			btn.addEventListener('click', this.onClickDelete);
		});

		this.initTabs()
	}

	initTabs() {
		const tabs = document.querySelector('#tabs')
		const activeTab = localStorage.getItem('stations-activeTab');

		tabs.addEventListener('shown.bs.tab', (e) => {
		   localStorage.setItem('stations-activeTab', e.target.dataset.bsTarget);
		});
		
		if(activeTab){
			const tab = new Tab(document.querySelector([`[data-bs-target="${activeTab}"]`]))
	   		tab.show();
		}
	}

	onClickDelete(e){
		const id = e.currentTarget.dataset.id;

		Swal.fire({
		    title: 'Êtes-vous sûr ?',
		    text: 'Cette équipement sera archivé !',
		    icon: 'warning',
		    showCancelButton: true,
		    confirmButtonColor: '#2d72bb',
		    cancelButtonColor: '#e3342f',
		    cancelButtonText: 'Annuler',
		    confirmButtonText: 'Confirmer'
		}).then((result) => {
		    if (result.value) {
		        axios.post('/stations/archive', {
		            id,
		        }).then( (response) => {
		            window.location.href = response.data;
		        })
		        .catch( (error) => {
		            console.log(error);
		        });
		    }
		});
	}

	onClickUnarchive(e){
		const id = e.currentTarget.dataset.id;

		axios.post('/stations/unarchive', {
			id
		}).then( response => {
			window.location.href = response.data
		}).catch( (error) => {
            console.log(error);
        });
	}
}