import moment from "moment";
import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js";
export default class TodoList {
	constructor() {
		this.todo = {
			container: document.querySelector(".todo-list"),
			addBtn: document.querySelector(".js-add-todo"),
		};

		this.todo.template = this.todo.container.querySelector(".js-todo-item").cloneNode(true);

		this.todo.addBtn.addEventListener("click", () => {
			this.create({ label: "", checked: false, opened: true });
		});
	}

	clearTodos() {
		this.todo.container.innerHTML = "";
	}

	create(item) {
		let todo = this.todo.template.cloneNode(true);

		todo.querySelector(".js-todo-label small").innerText = item.label;
		todo.querySelector(".js-todo-check").checked = item.checked == "true" ? true : false;
		// todo.querySelector(".js-todo-completed_date").innerText = item.date ? item.date : moment().format("YYYY-MM-DD HH:mm:ss");

		todo.querySelector(".js-todo-edit").addEventListener("click", this.toggleVisibility);
		todo.querySelector(".js-todo-button").addEventListener("click", this.toggleVisibility);

		let now = Math.random() * 10000000;
		todo.querySelector(".js-todo-check").id = "check-" + now.toFixed(2);
		todo.querySelector(".js-todo-label").setAttribute("for", "check-" + now.toFixed(2));

		this.todo.container.appendChild(todo);

		var picker = flatpickr(todo.querySelector(".js-todo-completed_date"), {
			enableTime: true,
			enableSeconds: true,
			minuteIncrement: 1,
			dateFormat: "Y-m-d H:i:S",
			locale: French,
			static: true,
		});

		picker.setDate(item.completed_on ? item.completed_on : new Date());

		if (item.opened) {
			todo.querySelector(".js-todo-edit").click();
		}

		let dateBadge = todo.querySelector(".js-todo-check");
		if (dateBadge) {
			todo.querySelector(".js-todo-completed_date").classList[dateBadge.checked ? "remove" : "add"]("d-none");
			dateBadge.addEventListener("change", e => {
				todo.querySelector(".js-todo-completed_date").classList[e.target.checked ? "remove" : "add"]("d-none");
			});
		}
	}

	getPayload() {
		let todos = [];
		let items = this.todo.container.querySelectorAll(".js-todo-item");

		if (items.length) {
			[...items].forEach(item => {
				let data = {
					label: item.querySelector(".js-todo-label small").innerText,
					checked: item.querySelector(".js-todo-check").checked,
				};

				if (item.querySelector(".js-todo-check").checked) {
					console.log('00000');
					data.completed_on = item.querySelector(".js-todo-completed_date").value;
				}

				todos.push(data);
			});
		}

		return todos;
	}

	set(event) {
		this.clearTodos();

		if (!event.todos) {
			return;
		}

		if (event.todos.length) {
			event.todos.forEach(item => {
				this.create(item);
			});
		}
	}

	toggleVisibility(e) {
		let container = e.currentTarget.closest(".js-todo-item");

		let label = container.querySelector(".js-todo-label small");
		let inputContainer = container.querySelector(".js-todo-input-container");
		let button = container.querySelector(".js-todo-button");
		let edit = container.querySelector(".js-todo-edit");
		let upper = container.querySelector(".js-todo-upper");
		let input = container.querySelector(".js-todo-input");
		let checkbox = container.querySelector(".js-todo-check");
		let datepicker = container.querySelector(".js-datetimepicker");
		let dateBadge = container.querySelector(".js-todo-completed_date");

		//not visible
		if (input.offsetParent === null) {
			input.value = label.innerHTML;
			inputContainer.classList.remove("d-none");
			edit.classList.add("d-none");
			inputContainer.classList.add("d-flex");
			upper.classList.add("d-none");
			input.focus();

			datepicker.classList[checkbox.checked ? "remove" : "add"]("d-none");
		} else {
			if (input.value.trim() == "") {
				container.remove();
				return;
			}
			label.innerText = input.value;
			inputContainer.classList.add("d-none");
			inputContainer.classList.remove("d-flex");
			edit.classList.remove("d-none");
			upper.classList.remove("d-none");
			datepicker.classList[checkbox.checked ? "remove" : "add"]("d-none");

			if (checkbox.checked) {
				dateBadge.innerText = datepicker.value;
			}
		}
	}
}
